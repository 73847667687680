/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        Slick.init();
        MatchHeight.initFooter();

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".menu-collapsed").click(function() {
          $(this).toggleClass("menu-expanded");
        }); 


        var equalheight = function(container) {

          var currentTallest = 0,
               currentRowStart = 0,
               rowDivs = new Array(),
               $el,
               topPosition = 0;
           $(container).each(function() {
          
             $el = $(this);
             $($el).height('auto');
             topPostion = $el.position().top;
          
             if (currentRowStart != topPostion) {
               for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                 rowDivs[currentDiv].height(currentTallest);
               }
               rowDivs.length = 0; // empty the array
               currentRowStart = topPostion;
               currentTallest = $el.height();
               rowDivs.push($el);
             } else {
               rowDivs.push($el);
               currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
             for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
               rowDivs[currentDiv].height(currentTallest);
             }
           });
          };
          
          $(window).load(function() {
            equalheight('.prodotto--brand');
          });
          
          
          $(window).resize(function(){
            equalheight('.prodotto--brand');
          });

          // STICKY NAV
          $(function () {
            $(window).scroll(function () {
                    // set distance user needs to scroll before we fadeIn navbar
              if ($(this).scrollTop() > 520) {
                $('.sticky').fadeIn();
              } else {
                $('.sticky').fadeOut();
              }
            }); 
        
          
          });
           
          $(function () {
            $('[data-toggle="tooltip"]').tooltip()
          })


      }
      
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var Slick = {
    init: function() {      
      $('.slider--carousel').slick({
        dots: true,
        infinite: true,        
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      });
      $('.slider--gallery').slick({
        dots: false,
        infinite: true,
        arrows:false,
        slidesToShow: 6,
        slidesToScroll: 1
      });
   
      $('.slider--carousel_mobile').slick({
        dots: true,
        infinite: true,
        arrows:false,


        responsive: [

          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              dots:true,

            }
          },
 
          {
            breakpoint: 767,
            settings: {
              dots: true,
              infinite: true,
              arrows:false,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay:true,

            }
          },
       
        ]





      }); 
      $('.slider--news_mobile').slick({
   
        responsive: [

          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              dots:true,

            }
          },

          {
            breakpoint: 767,
            settings: {
              dots: true,
              infinite: true,
              arrows:false,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay:true,

            }
          },
       
        ]
      });
      $('.slider--samecat').slick({
        dots: false,
        infinite: true,
        arrows:false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [

          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              dots:true,

            }
          },

          {
            breakpoint: 767,
            settings: {
              dots: true,
              infinite: true,
              arrows:false,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay:true,

            }
          },
       
        ]
      });
      $('.slider--prodotto_mobile').slick({
        dots: true,
        infinite: true,
        arrows:false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true

      });
      $('.slider--home').slick({
        dots: true,
        autoplay:true,
        infinite: true,
        arrows:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [

          {
            breakpoint: 991,
            settings: {
              arrows: false,
             // centerPadding: '40px',
              slidesToShow: 1,
              slidesToScroll: 1,

            }
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
             // centerPadding: '40px',
              slidesToShow: 1,
              slidesToScroll: 1,

            }
          },
       
        ]
      });
      
      $('.slider--milestone').slick({
        dots: true,
        infinite: false,
        arrows:true,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [

          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
/*               centerMode: true,
 */              centerPadding: '40px',
              slidesToShow: 1,
            }
          },
       
        ]
      });

      $('.slider--impegno').slick({
        dots: false,
        infinite: false,
        arrows:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [

          {
            breakpoint: 768,
            settings: {
              dots: true,

              arrows: false,
              //centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          },
       
        ]
      });

      $('.slider--media').slick({
        dots: true,
        infinite: false,
        arrows:true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [

          {
            breakpoint: 768,
            settings: {
              dots: true,

              arrows: false,
              //centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          },
       
        ]
      });
  
    } 
  };
//Full screen slider home
  function fullscreen(){
    $('slide__wrapper--home').css({
        width: jQuery(window).width(),
        height: jQuery(window).height()
    });
}

fullscreen();
$(window).resize(function() {
  fullscreen();         
});
//
  var MatchHeight = {
    initFooter: function() {
      $('.footer__item').matchHeight();
    }
  };
})(jQuery); // Fully reference jQuery after this point.

